.loginLink {
    color: #4050B5;
    padding: 10px;
    text-decoration: none;
    font-size: 14px;
    display: flex;
    gap: 6px;
    align-items: center;
    border-radius: 8px;
    background: #e8eefb;
}

.loginLink:hover {
    filter: brightness(120%);
}
